const Layer = resolve => require(['@/views/layer/layer.js'], resolve);

//概况
const General = resolve => require(['@/views/shop/general'], resolve);
//概况
const Statistics = resolve => require(['@/views/shop/statistics'], resolve);
//打卡
const Clock = resolve => require(['@/views/shop/clock'], resolve);
//预约看板
const AppointmentBoard = resolve => require(['@/views/shop/appointmentBoard'], resolve);
//新增预约
const AddAppointment = resolve => require(['@/views/shop/addAppointment'], resolve);
//预约列表
const AppointmentList = resolve => require(['@/views/shop/appointmentList'], resolve);
//预约设置
const AppointmentSetting = resolve => require(['@/views/shop/appointmentSetting'], resolve);
//预约详情
const AppointmentDetail = resolve => require(['@/views/shop/appointmentDetail'], resolve);
//卡项列表
const CardList = resolve => require(['@/views/shop/cardList'], resolve);
//卡项列表
const CardDetail = resolve => require(['@/views/shop/cardDetail'], resolve);
//新增卡项
const AddCard = resolve => require(['@/views/shop/addCard'], resolve);
//链接大全
const Links = resolve => require(['@/views/shop/links'], resolve);
//业绩提成
const PerformanceGains = resolve => require(['@/views/shop/performanceGains'], resolve);
//产品列表
const ProductList = resolve => require(['@/views/shop/productList'], resolve);
//产品列表
const AddProduct = resolve => require(['@/views/shop/addProduct'], resolve);
//产品列表
const ProductDetail = resolve => require(['@/views/shop/productDetail'], resolve);
//服务列表
const ServiceList = resolve => require(['@/views/shop/serviceList'], resolve);
//服务详情
const ServiceDetail = resolve => require(['@/views/shop/serviceDetail'], resolve);
//新增服务
const AddService = resolve => require(['@/views/shop/addService'], resolve);
//网店装修
const ShopDecoration = resolve => require(['@/views/shop/shopDecoration'], resolve);
//网店装修编辑
const ShopPackage = resolve => require(['@/views/shop/shopPackage'], resolve);
//网店app
const ShopGroup = resolve => require(['@/views/shop/shopPackage/pc/group'], resolve);
//网店center
const ShopMyCenter = resolve => require(['@/views/shop/shopPackage/pc/other'], resolve);

//服务列表Layer
const ServiceListLayer = resolve => require(['@/views/shop/serviceListLayer'], resolve);
//卡项列表Layer
const CardListLayer = resolve => require(['@/views/shop/cardListLayer'], resolve);
//产品列表Layer
const ProductListLayer = resolve => require(['@/views/shop/productListLayer'], resolve);

//员工列表Layer
const StaffListLayer = resolve => require(['@/views/shop/staffListLayer'], resolve);
//员工列表
const StaffList = resolve => require(['@/views/shop/staffList'], resolve);
//添加员工
const StaffAdd = resolve => require(['@/views/shop/staffAdd'], resolve);
//员工详情
const StaffDetail = resolve => require(['@/views/shop/staffDetail'], resolve);
//员工管理
const StaffEdit = resolve => require(['@/views/shop/staffEdit'], resolve);
//修改密码
const StaffPwd = resolve => require(['@/views/shop/staffPwd'], resolve);
//角色管理列表
const ActorList = resolve => require(['@/views/shop/actorList'], resolve);
//新增角色
const ActorAdd = resolve => require(['@/views/shop/actorAdd'], resolve);
//编辑角色
const ActorEdit = resolve => require(['@/views/shop/actorEdit'], resolve);
//角色详情
const ActorDetail = resolve => require(['@/views/shop/actorDetail'], resolve);
//已禁用账号
const StaffForbid = resolve => require(['@/views/shop/staffForbid'], resolve);

//服务提醒
const ServiceReminder = resolve => require(['@/views/shop/serviceReminder'], resolve);
//班次管理
const StaffScheduling = resolve => require(['@/views/shop/staffScheduling'], resolve);
//班次管理
const StaffRoster = resolve => require(['@/views/shop/staffRoster'], resolve);
//库存管理
const StockManage = resolve => require(['@/views/shop/stockManage'], resolve);
//新增入库
const StockAdd = resolve => require(['@/views/shop/stockAdd'], resolve);
//新增出库
const StockOut = resolve => require(['@/views/shop/stockOut'], resolve);
//库存详情
const StockDetail = resolve => require(['@/views/shop/stockDetail'], resolve);
//员工考勤
const WorkAttendance = resolve => require(['@/views/shop/workAttendance'], resolve);
//月度汇总
const MonthSummary = resolve => require(['@/views/shop/monthSummary'], resolve);

//商品评价
const EvaluationManage = resolve => require(['@/views/shop/evaluationManage'], resolve);
//商品评价
const ProductEvalDetail = resolve => require(['@/views/shop/productEvalDetail'], resolve);
//店铺列表
const ShopList = resolve => require(['@/views/shop/shopList'], resolve);
//创建店铺
const CreateShop = resolve => require(['@/views/shop/createShop'], resolve);
//账号设置
const AccountSetting = resolve => require(['@/views/shop/accountSetting'], resolve);
//快递公司设置
const LogisticsCompany = resolve => require(['@/views/shop/logisticsCompany'], resolve);

//订单列表
const OrderList = resolve => require(['@/views/shop/orderList'], resolve);
//订单列表
const ServiceOrderList = resolve => require(['@/views/shop/serviceOrderList'], resolve);
//订单列表
const CardOrderList = resolve => require(['@/views/shop/cardOrderList'], resolve);

//创建订单
const CreateOrder = resolve => require(['@/views/shop/createOrder'], resolve);
//订单详情
const OrderDetail = resolve => require(['@/views/shop/orderDetail'], resolve);
//订单退款
const OrderRefund = resolve => require(['@/views/shop/refund'], resolve);

//平台介入
const PlatformIntervenes = resolve => require(['@/views/shop/platformIntervenes'], resolve);

//客户列表
const CustomerList = resolve => require(['@/views/shop/customerList'], resolve);
//添加客户
const AddCustomer = resolve => require(['@/views/shop/addCustomer'], resolve);
//客户详情
const customerDetail = resolve => require(['@/views/shop/customerDetail'], resolve);
//卡项列表
const CustomerCardDetail = resolve => require(['@/views/shop/customerCardDetail'], resolve);
//通用设置
const CommonSetting = resolve => require(['@/views/shop/commonSetting'], resolve);
//监控设置
const MonitorSetting = resolve => require(['@/views/shop/monitorSetting'], resolve);
//收款类型设置
const PaymentSetting = resolve => require(['@/views/shop/paymentSetting'], resolve);
//店铺设置
const ShopSetting = resolve => require(['@/views/shop/shopSetting'], resolve);
//第三方设置
const PaySetting = resolve => require(['@/views/shop/paySetting'], resolve);
//编辑门店
const EditShopInfo = resolve => require(['@/views/shop/shopSetting/edit'], resolve);
//店铺认证引导
const ShopAuthGuide = resolve => require(['@/views/shop/shopSetting/authGuide'], resolve);
//店铺认证
const ShopAuth = resolve => require(['@/views/shop/shopSetting/auth'], resolve);
//个人信息
const MyInfo = resolve => require(['@/views/shop/myInfo'], resolve);
//发货设置
// const ShipmentSetting = resolve => require(['@/views/shop/shipmentSetting'], resolve);
// 分销员管理
const SalesList = resolve => require(['@/views/shop/salesList'], resolve);
//添加员工
const SalesAdd = resolve => require(['@/views/shop/salesAdd'], resolve);
// 分销员详情
const SalesDetail = resolve => require(['@/views/shop/salesDetail'], resolve);
//修改密码
const PromoterPwd = resolve => require(['@/views/shop/promoterPwd'], resolve);

//推广员控制台容器
const PromoterConsoleLayer = resolve => require(['@/views/shop/promoterConsoleLayer'], resolve);
//推广员控制台
const PromoterConsole = resolve => require(['@/views/shop/promoterConsole'], resolve);
//推广员客户
const PromoterCustomer = resolve => require(['@/views/shop/promoterCustomer'], resolve);
//推广员推广额记录
const PromoterRecord = resolve => require(['@/views/shop/promoterRecord'], resolve);
//推广员客户详情
const PromoterCustomerDetail = resolve => require(['@/views/shop/customerDetail'], resolve);

//宠物列表
const PetList = (resolve) => require(["@/views/shop/petList"], resolve);
//新增宠物
const PetAdd = (resolve) => require(["@/views/shop/petAdd"], resolve);
//编辑宠物
const PetEdit = (resolve) => require(["@/views/shop/petAdd"], resolve);
//宠物详情
const PetDetail = (resolve) => require(["@/views/shop/petDetail"], resolve);
//新增 (就诊记录/检查报告/疫苗记录)
const AddPetRecord = (resolve) => require(["@/views/shop/addPetRecord"], resolve);
const AddPetRecordCustom = (resolve) => require(["@/views/shop/addPetRecordCustom"], resolve);
//编辑 (就诊记录/检查报告/疫苗记录)
const EditPetRecord = (resolve) => require(["@/views/shop/addPetRecord"], resolve);
const EditPetRecordCustom = (resolve) => require(["@/views/shop/addPetRecordCustom"], resolve);
//(就诊记录/检查报告)详情
const PetRecordDetail = (resolve) => require(["@/views/shop/petRecordDetail"], resolve);
//(疫苗记录)详情
const PetRecordDetailCustom = (resolve) => require(["@/views/shop/petRecordDetailCustom"], resolve);

//类目管理
const CategoryManage = resolve => require(['@/views/shop/categoryManage'], resolve);
//编辑类目
const CategoryEdit = resolve => require(['@/views/shop/categoryEdit'], resolve);

//编辑项目
const ProjectEdit = resolve => require(['@/views/shop/projectEdit'], resolve);

//主账户资料详情
const MerchantInfo = resolve => require(['@/views/shop/merchantInfo'], resolve);

//优惠方案
const DiscountProgram = resolve => require(['@/views/shop/discountProgram'], resolve);
//添加优惠方案
const DiscountAdd = resolve => require(['@/views/shop/discountAdd'], resolve);
//编辑优惠方案
const DiscountEdit = resolve => require(['@/views/shop/discountAdd'], resolve);
//优惠方案详情
const DiscountDetail = resolve => require(['@/views/shop/discountDetail'], resolve);

//提成方案
const CommissionProgram = resolve => require(['@/views/shop/commissionProgram'], resolve);
//添加提成方案
const CommissionAdd = resolve => require(['@/views/shop/commissionAdd'], resolve);
//编辑提成方案 
const CommissionEdit = resolve => require(['@/views/shop/commissionAdd'], resolve);
//提成方案详情
const CommissionDetail = resolve => require(['@/views/shop/commissionDetail'], resolve);

import { $t } from '@/pages/i18n'

/**
 * 多级子路由在Layer的children后面继续添加 不需要再在children中嵌套children
 * 
 * id:100 (:todo)
 */

const shopRouter = [
  {
    path: '/shopList',
    meta: {
      title: $t('dian-pu'),
      icon: 'iconfont al-icon-gaikuang',
      icon_m: 'iconfont al-icon-gaikuang',
      id: 100,
      show: false
    },
    redirect: '/shopList/index',
    component: Layer,
    children: [{
      path: '/shopList/index',
      name: 'shopList',
      component: ShopList,
      meta: {
        title: $t('dian-pu-lie-biao'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 100,
        show: false
      }
    }, {
      path: '/shopList/createShop',
      name: 'createShop',
      component: CreateShop,
      meta: {
        title: $t('chuang-jian-dian-pu-0'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 100,
        show: false
      }
    }, {
      path: '/shopList/accountSetting',
      name: 'accountSetting',
      component: AccountSetting,
      meta: {
        title: $t('zhang-hao-she-zhi'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 100,
        show: false
      }
    }, {
      path: '/shopList/merchantInfo',
      name: 'merchantInfo',
      component: MerchantInfo,
      meta: {
        title: $t('zhang-hu-xiang-qing'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 100,
        show: false
      }
    }]
  },
  {
    path: '/general',
    meta: {
      title: $t('gai-kuang'),
      icon: 'iconfont al-icon-gaikuang',
      icon_m: 'iconfont al-icon-gaikuang',
      id: 1000,
      show: true
    },
    redirect: '/general/index',
    component: Layer,
    children: [{
      path: '/general/index',
      name: 'general',
      component: General,
      meta: {
        title: $t('gai-kuang-0'),
        icon: 'el-icon-menu',
        id: 1000,
        icon_m: 'md-apps',
        show: false
      }
    }]
  },
  {
    path: '/serviceReminder',
    meta: {
      title: $t('ren-wu'),
      icon: 'iconfont al-icon-tuiguang',
      icon_m: 'iconfont al-icon-tuiguang',
      id: 1010,
      show: true
    },
    redirect: '/serviceReminder/index',
    component: Layer,
    children: [{
      path: '/serviceReminder/index',
      name: 'serviceReminder',
      component: ServiceReminder,
      meta: {
        title: $t('fu-wu-ti-xing'),
        id: 1010001,
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: true
      }
    }
    ]
  },
  {
    path: '/clock',
    meta: {
      title: $t('da-ka'),
      icon: 'iconfont al-icon-gaikuang',
      icon_m: 'iconfont al-icon-gaikuang',
      id: 1009,
      showInMobile: true,
      show: true
    },
    redirect: '/clock/index',
    component: Layer,
    children: [{
      path: '/clock/index',
      name: 'clock',
      component: Clock,
      meta: {
        title: $t('da-ka-0'),
        icon: 'el-icon-menu',
        id: 1009,
        icon_m: 'md-apps',
        show: false
      }
    }]
  },
  {
    path: '/staff',
    meta: {
      title: process.env.PLATFORM_TYPE === 'ec' ? $t('shang-hu-0') : $t('yuan-gong-0'),
      icon: 'iconfont al-icon-yuangong',
      icon_m: 'iconfont al-icon-yuangong',
      id: 1002,
      show: true
    },
    redirect: '/staff/staffList',
    component: Layer,
    children: [{
      path: '/staff/staffList',
      name: 'staffListLayer',
      redirect: '/staff/staffList/staffList',
      component: StaffListLayer,
      meta: {
        title: process.env.PLATFORM_TYPE === 'ec' ? $t('shang-hu-lie-biao') : $t('yuan-gong-lie-biao'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1002001,
        show: true
      },
      children: [
        {
          path: '/staff/staffList/staffList',
          name: 'staffList',
          component: StaffList,
          meta: {
            title: $t('yuan-gong-lie-biao-0'),
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            id: 1002001001,
            show: false
          }
        }, {
          path: '/staff/staffList/staffAdd',
          name: 'staffAdd',
          component: StaffAdd,
          meta: {
            title: $t('tian-jia-yuan-gong'),
            icon: 'el-icon-menu',
            id: 1002001001,
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/staffDetail',
          name: 'staffDetail',
          component: StaffDetail,
          meta: {
            title: $t('yuan-gong-lie-biao-1'),
            icon: 'el-icon-menu',
            id: 1002001001,
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/staffEdit',
          name: 'staffEdit',
          component: StaffEdit,
          meta: {
            title: $t('yuan-gong-guan-li'),
            icon: 'el-icon-menu',
            id: 1002001001,
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/staffPwd',
          name: 'staffPwd',
          component: StaffPwd,
          meta: {
            title: $t('xiu-gai-mi-ma'),
            icon: 'el-icon-menu',
            id: 1002001001,
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/actorList',
          name: 'actorList',
          component: ActorList,
          meta: {
            title: $t('jiao-se-guan-li-lie-biao'),
            icon: 'el-icon-menu',
            id: 1002001002,
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/actorAdd',
          name: 'actorAdd',
          component: ActorAdd,
          meta: {
            title: $t('xin-zeng-jiao-se'),
            id: 1002001002,
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/actorEdit',
          name: 'actorEdit',
          component: ActorEdit,
          meta: {
            title: $t('bian-ji-jiao-se'),
            id: 1002001002,
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/actorDetail',
          name: 'actorDetail',
          component: ActorDetail,
          meta: {
            title: $t('jiao-se-xiang-qing'),
            id: 1002001002,
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/forbid',
          name: 'staffForbid',
          component: StaffForbid,
          meta: {
            title: $t('yi-jin-yong-zhang-hao'),
            id: 1002001003,
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }]
    }, {
      path: '/staff/staffScheduling',
      name: 'staffScheduling',
      component: StaffScheduling,
      meta: {
        title: $t('ban-ci-guan-li'),
        id: 1002002,
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: true
      }
    }, {
      path: '/staff/staffRoster',
      name: 'staffRoster',
      component: StaffRoster,
      meta: {
        title: $t('yuan-gong-ban-biao'),
        id: 1002005,
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: true
      }
    }, {
      path: '/staff/workAttendance',
      name: 'workAttendance',
      component: WorkAttendance,
      meta: {
        title: $t('yuan-gong-kao-qin'),
        id: 1002003,
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: true
      }
    }, {
      path: '/staff/workAttendance/monthSummary',
      name: 'monthSummary',
      component: MonthSummary,
      meta: {
        title: $t('yue-du-hui-zong'),
        id: 1002003001,
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/staff/performanceGains',
      name: 'performanceGains',
      component: PerformanceGains,
      meta: {
        title: $t('ye-ji-ti-cheng'),
        id: 1002004,
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: true
      }
    },
    {
      path: '/staff/salesList',
      name: 'salesList',
      component: SalesList,
      meta: {
        title: $t('tui-guang-yuan-guan-li'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1012001,
        show: true
      }
    }, {
      path: '/staff/salesList/salesAdd',
      name: 'salesAdd',
      component: SalesAdd,
      meta: {
        title: $t('tian-jia-tui-guang-yuan'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1012001,
        show: false
      }
    },
    {
      path: '/staff/salesList/salesDetail',
      name: 'salesDetail',
      component: SalesDetail,
      meta: {
        title: $t('tui-guang-yuan-xiang-qing'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1012001,
        show: false
      }
    },
    {
      path: '/staff/salesList/staffPwd',
      name: 'promoterPwd',
      component: PromoterPwd,
      meta: {
        title: $t('xiu-gai-mi-ma'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1012001,
        show: false
      }
    },
    {
      path: '/staff/console',
      name: 'promoterConsoleLayer',
      redirect: '/staff/console/index',
      component: PromoterConsoleLayer,
      meta: {
        title: $t('tui-guang-yuan-kong-zhi-tai'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1012002,
        show: true
      },
      children: [
        {
          path: '/staff/console/index',
          name: 'promoterConsole',
          component: PromoterConsole,
          meta: {
            title: $t('tui-guang-yuan-kong-zhi-tai-0'),
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            id: 1012002,
            show: false
          }
        },
        {
          path: '/staff/console/record',
          name: 'promoterRecord',
          component: PromoterRecord,
          meta: {
            title: $t('tui-guang-shou-yi-ming-xi'),
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            id: 1012002,
            show: false
          }
        }
      ]
    }, {
      path: '/staff/console/customer',
      name: 'promoterCustomer',
      component: PromoterCustomer,
      meta: {
        title: $t('tui-guang-yuan-ke-hu'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1012002,
        show: false
      }
    }, {
      path: '/staff/console/customer/detail',
      name: 'promoterCustomerDetail',
      component: PromoterCustomerDetail,
      meta: {
        title: $t('tui-guang-yuan-ke-hu-xiang-qing'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1012002,
        show: false
      }
    }]
  },
  {
    path: "/mypet",
    name: "mypet",
    meta: {
      title: $t('chong-wu'),
      icon: "iconfont icon-chongwu",
      icon_m: "iconfont icon-chongwu",
      id: 1013,
      show: true,
    },
    redirect: "/mypet/petList",
    component: Layer,
    children: [
      {
        path: "/mypet/petList",
        name: "petList",
        component: PetList,
        meta: {
          title: $t('chong-wu-lie-biao'),
          icon: "el-icon-menu",
          id: 1013,
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/mypet/petAdd",
        name: "petAdd",
        component: PetAdd,
        meta: {
          title: $t('xin-zeng-chong-wu'),
          icon: "el-icon-menu",
          id: 1013,
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/mypet/PetEdit",
        name: "PetEdit",
        component: PetEdit,
        meta: {
          title: $t('bian-ji-chong-wu'),
          icon: "el-icon-menu",
          id: 1013,
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/mypet/petDetail",
        name: "petDetail",
        component: PetDetail,
        meta: {
          title: $t('chong-wu-xiang-qing'),
          icon: "el-icon-menu",
          id: 1013,
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/mypet/addPetRecord",
        name: "addPetRecord",
        component: AddPetRecord,
        meta: {
          title: $t('xin-zeng-jiu-zhen-ji-lu'),
          icon: "el-icon-menu",
          id: 1013,
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/mypet/addPetRecordCustom",
        name: "addPetRecordCustom",
        component: AddPetRecordCustom,
        meta: {
          title: $t('xin-zeng-jiu-zhen-ji-lu-0'),
          icon: "el-icon-menu",
          id: 1013,
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/mypet/editPetRecord",
        name: "editPetRecord",
        component: EditPetRecord,
        meta: {
          title: $t('bian-ji-jiu-zhen-ji-lu'),
          icon: "el-icon-menu",
          id: 1013,
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/mypet/editPetRecordCustom",
        name: "editPetRecordCustom",
        component: EditPetRecordCustom,
        meta: {
          title: $t('bian-ji-jiu-zhen-ji-lu-0'),
          icon: "el-icon-menu",
          id: 1013,
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/mypet/petRecordDetail",
        name: "pPetRecordDetail",
        component: PetRecordDetail,
        meta: {
          title: $t('jiu-zhen-ji-lu-xiang-qing'),
          icon: "el-icon-menu",
          id: 1013,
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/mypet/petRecordDetailCustom",
        name: "pPetRecordDetailCustom",
        component: PetRecordDetailCustom,
        meta: {
          title: $t('jiu-zhen-ji-lu-xiang-qing'),
          icon: "el-icon-menu",
          id: 1013,
          icon_m: "md-apps",
          show: false,
        },
      },
    ],
  },
  {
    path: '/appointment',
    meta: {
      title: $t('yu-yue'),
      icon: 'iconfont al-icon-reserve',
      icon_m: 'iconfont al-icon-reserve',
      id: 1003,
      show: true
    },
    redirect: '/appointment/appointmentList',
    component: Layer,
    children: [{
      path: '/appointment/appointmentList',
      name: 'appointmentList',
      component: AppointmentList,
      meta: {
        title: $t('yu-yue-lie-biao'),
        icon: 'el-icon-menu',
        id: 1003001,
        icon_m: 'md-apps',
        show: true
      }
    }, {
      path: '/appointment/appointmentBoard',
      name: 'appointmentBoard',
      component: AppointmentBoard,
      meta: {
        title: $t('yu-yue-kan-ban'),
        icon: 'el-icon-menu',
        id: 1003002,
        icon_m: 'md-apps',
        show: true
      }
    }, {
      path: '/appointment/appointmentSetting',
      name: 'appointmentSetting',
      component: AppointmentSetting,
      meta: {
        title: $t('yu-yue-she-zhi'),
        icon: 'el-icon-menu',
        id: 1003003,
        icon_m: 'md-apps',
        show: true
      }
    }, {
      path: '/appointment/appointmentList/addAppointment',
      name: 'addAppointment',
      component: AddAppointment,
      meta: {
        title: $t('xin-zeng-yu-yue'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1003001,
        show: false
      }
    }, {
      path: '/appointment/appointmentList/detail',
      name: 'appointmentDetail',
      component: AppointmentDetail,
      meta: {
        title: $t('yu-yue-xiang-qing'),
        icon: 'el-icon-menu',
        id: 1003001,
        icon_m: 'md-apps',
        show: false
      }
    }]
  },
  {
    path: '/product',
    meta: {
      title: $t('shang-pin-0'),
      icon: 'iconfont al-icon-shangpin',
      icon_m: 'iconfont al-icon-shangpin',
      id: 1004,
      show: true
    },
    redirect: '/product/serviceList',
    component: Layer,
    children: [
      {
        path: '/product/serviceListLayer',
        name: 'serviceListLayer',
        redirect: '/product/serviceListLayer/serviceList',
        component: ServiceListLayer,
        meta: {
          title: $t('fu-wu-lie-biao'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1004001,
          show: true
        },
        children: [{
          path: '/product/serviceListLayer/serviceList',
          name: 'serviceList',
          component: ServiceList,
          meta: {
            title: $t('fu-wu-lie-biao'),
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            id: 1004001,
            show: false
          }
        }, {
          path: '/product/serviceListLayer/serviceList/serviceDetail',
          name: 'serviceDetail',
          component: ServiceDetail,
          meta: {
            title: $t('fu-wu-xiang-qing'),
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            id: 1004001,
            show: false
          }
        }, {
          path: '/product/serviceListLayer/serviceList/addService',
          name: 'addService',
          component: AddService,
          meta: {
            title: $t('xin-zeng-fu-wu'),
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            id: 1004001,
            show: false
          }
        }],
      },
      {
        path: '/product/cardListLayer',
        name: 'cardListLayer',
        redirect: '/product/cardListLayer/cardList',
        component: CardListLayer,
        meta: {
          title: $t('ka-xiang-lie-biao'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1004002,
          show: true
        },
        children: [
          {
            path: '/product/cardListLayer/cardList',
            name: 'cardList',
            component: CardList,
            meta: {
              title: $t('ka-xiang-lie-biao-0'),
              icon: 'el-icon-menu',
              icon_m: 'md-apps',
              id: 1004002,
              show: false
            }
          }, {
            path: '/product/cardListLayer/cardList/addCard',
            name: 'addCard',
            component: AddCard,
            meta: {
              title: $t('xin-zeng-ka-xiang'),
              icon: 'el-icon-menu',
              icon_m: 'md-apps',
              id: 1004002,
              show: false
            }
          }, {
            path: '/product/cardListLayer/cardList/cardDetail',
            name: 'cardDetail',
            component: CardDetail,
            meta: {
              title: $t('ka-xiang-xiang-qing'),
              icon: 'el-icon-menu',
              icon_m: 'md-apps',
              id: 1004002,
              show: false
            }
          }],
      },
      {
        path: '/product/productListLayer',
        name: 'productListLayer',
        redirect: '/product/productListLayer/productList',
        component: ProductListLayer,
        meta: {
          title: process.env.PLATFORM_TYPE === 'ec' ? $t('shang-pin-lie-biao') : $t('chan-pin-lie-biao'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1004003,
          show: true
        },
        children: [
          {
            path: '/product/productListLayer/productList',
            name: 'productList',
            component: ProductList,
            meta: {
              title: $t('chan-pin-lie-biao-0'),
              icon: 'el-icon-menu',
              icon_m: 'md-apps',
              id: 1004003,
              show: false
            }
          }, {
            path: '/product/productListLayer/productList/addProduct',
            name: 'addProduct',
            component: AddProduct,
            meta: {
              title: $t('xin-zeng-chan-pin'),
              icon: 'el-icon-menu',
              icon_m: 'md-apps',
              id: 1004003,
              show: false
            }
          }, {
            path: '/product/productListLayer/productList/productDetail',
            name: 'productDetail',
            component: ProductDetail,
            meta: {
              title: $t('chan-pin-xiang-qing'),
              icon: 'el-icon-menu',
              icon_m: 'md-apps',
              id: 1004003,
              show: false
            }
          }]
      }, {
        path: '/product/stockManage',
        name: 'stockManage',
        component: StockManage,
        meta: {
          title: $t('ku-cun-guan-li'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1004004,
          show: true
        }
      },
      {
        path: '/product/discount',
        name: 'discountProgram',
        component: DiscountProgram,
        meta: {
          title: $t('you-hui-fang-an'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1004006,
          show: true,
        }
      },{
        path: '/product/discountAdd',
        name: 'discountAdd',
        component: DiscountAdd,
        meta: {
          title: $t('xin-zeng-you-hui-fang-an'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1004006,
          show: false,
        }
      },{
        path: '/product/discountEdit',
        name: 'discountEdit',
        component: DiscountEdit,
        meta: {
          title: $t('bian-ji-you-hui-fang-an'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1004006,
          show: false,
        }
      },{
        path: '/product/discountDetail',
        name: 'discountDetail',
        component: DiscountDetail,
        meta: {
          title: $t('bian-ji-you-hui-fang-an'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1004006,
          show: false,
        }
      },
      {
        path: '/product/stockManage/stockAdd',
        name: 'stockAdd',
        component: StockAdd,
        meta: {
          title: $t('xin-zeng-ru-ku'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1004004,
          show: false
        }
      }, {
        path: '/product/stockManage/stockOut',
        name: 'stockOut',
        component: StockOut,
        meta: {
          title: $t('xin-zeng-chu-ku'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1004004,
          show: false
        }
      }, {
        path: '/product/stockManage/stockDetail',
        name: 'stockDetail',
        component: StockDetail,
        meta: {
          title: $t('ku-cun-xiang-qing-ye'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1004004,
          show: false
        }
      }, {
        path: '/product/evaluationManage',
        name: 'evaluationManage',
        component: EvaluationManage,
        meta: {
          title: $t('shang-pin-ping-jia'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1004005,
          show: true
        }
      }, {
        path: '/product/evaluationManage/productEvalDetail',
        name: 'productEvalDetail',
        component: ProductEvalDetail,
        meta: {
          title: $t('ping-jia-xiang-qing'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1004005,
          show: false
        }
      }]
  },
  {
    path: '/order',
    meta: {
      title: $t('ding-dan'),
      icon: 'iconfont al-icon-dingdan',
      icon_m: 'iconfont al-icon-dingdan',
      id: 1005,
      show: true
    },
    redirect: '/order/serviceOrder/list',
    component: Layer,
    children: [{
      path: '/order/serviceOrder/list',
      name: 'serviceOrderList',
      component: ServiceOrderList,
      meta: {
        title: $t('fu-wu-ding-dan'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1005001,
        show: true,
        keepAlive: true
      }
    }, {
      path: '/order/cardOrder/list',
      name: 'cardOrderList',
      component: CardOrderList,
      meta: {
        title: $t('ka-xiang-he-chong-zhi-ding-dan-0'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1005001,
        show: true,
        keepAlive: true
      }
    }, {
      path: '/order/list',
      name: 'orderList',
      component: OrderList,
      meta: {
        title: $t('shang-pin-ding-dan'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1005001,
        show: true,
        keepAlive: true
      }
    },{
      path: '/order/platformIntervenes/index',
      name: 'platformIntervenes',
      component: PlatformIntervenes,
      meta: {
        title: $t('ping-tai-jie-ru-0'),
        icon: 'el-icon-menu',
        id: 1005014,
        icon_m: 'md-apps',
        show: true
      }
    }, {
      path: '/order/serviceOrder/list/create',
      name: 'createServiceOrder',
      component: CreateOrder,
      meta: {
        title: $t('chuang-jian-ding-dan'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1005001,
        show: false
      }
    }, {
      path: '/order/serviceOrder/list/detail',
      name: 'serviceOrderDetail',
      component: OrderDetail,
      meta: {
        title: $t('ding-dan-xiang-qing'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1005001,
        show: false
      }
    }, {
      path: '/order/serviceOrder/list/refund',
      name: 'serviceOrderRefund',
      component: OrderRefund,
      meta: {
        title: $t('ding-dan-tui-kuan'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1005001,
        show: false
      }
    }, {
      path: '/order/cardOrder/list/create',
      name: 'createCardOrder',
      component: CreateOrder,
      meta: {
        title: $t('chuang-jian-ding-dan-0'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1005001,
        show: false
      }
    }, {
      path: '/order/cardOrder/list/detail',
      name: 'cardOrderDetail',
      component: OrderDetail,
      meta: {
        title: $t('ding-dan-xiang-qing'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1005001,
        show: false
      }
    }, {
      path: '/order/cardOrder/list/refund',
      name: 'cardOrderRefund',
      component: OrderRefund,
      meta: {
        title: $t('ding-dan-tui-kuan-0'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1005001,
        show: false
      }
    }, {
      path: '/order/list/create',
      name: 'createOrder',
      component: CreateOrder,
      meta: {
        title: $t('chuang-jian-ding-dan-1'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1005001,
        show: false
      }
    }, {
      path: '/order/list/detail',
      name: 'orderDetail',
      component: OrderDetail,
      meta: {
        title: $t('ding-dan-xiang-qing'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1005001,
        show: false
      }
    }, {
      path: '/order/list/refund',
      name: 'orderRefund',
      component: OrderRefund,
      meta: {
        title: $t('ding-dan-tui-kuan-1'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1005001,
        show: false
      }
    }]
  },
  {
    path: '/statistics',
    meta: {
      title: $t('shu-ju'),
      icon: 'iconfont al-icon-shuju',
      icon_m: 'iconfont al-icon-shuju',
      id: 1007,
      show: true
    },
    redirect: '/statistics/index',
    component: Layer,
    children: [{
      path: '/statistics/index',
      name: 'statistics',
      component: Statistics,
      meta: {
        title: $t('shu-ju'),
        icon: 'el-icon-menu',
        id: 1007,
        icon_m: 'md-apps',
        show: false
      }
    }]
  },
  {
    path: '/customer',
    meta: {
      title: $t('ke-hu'),
      icon: 'iconfont al-icon-kehu',
      icon_m: 'iconfont al-icon-kehu',
      id: 1006,
      show: true
    },
    redirect: '/customer/list',
    component: Layer,
    children: [{
      path: '/customer/list',
      name: 'customerList',
      component: CustomerList,
      meta: {
        title: $t('ke-hu-lie-biao'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1006001,
        show: true
      }
    }, {
      path: '/customer/list/add',
      name: 'customerAdd',
      component: AddCustomer,
      meta: {
        title: $t('xin-zeng-ke-hu'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1006001,
        show: false
      }
    }, {
      path: '/customer/list/detail',
      name: 'customerDetail',
      component: customerDetail,
      meta: {
        title: $t('ke-hu-xiang-qing'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1006001,
        show: false
      }
    }, {
      path: '/customer/list/cardDetail',
      name: 'customerCardDetail',
      component: CustomerCardDetail,
      meta: {
        title: $t('ke-hu-ka-xiang-xiang-qing'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1006001,
        show: false
      }
    }]
  },
  {
    path: '/setting',
    meta: {
      title: $t('she-zhi'),
      icon: 'iconfont al-icon-shezhi',
      icon_m: 'iconfont al-icon-shezhi',
      id: 1008,
      show: true
    },
    redirect: '/setting/common',
    component: Layer,
    children: [{
      path: '/setting/common',
      name: 'commonSetting',
      component: CommonSetting,
      meta: {
        title: $t('tong-yong-she-zhi'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008001,
        show: true
      }
    }, {
      path: '/setting/shop/index',
      name: 'shopSetting',
      component: ShopSetting,
      meta: {
        title: $t('dian-pu-she-zhi'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008001,
        show: true
      }
    }, {
      path: '/setting/shop/shopDecoration',
      name: 'shopDecoration',
      component: ShopDecoration,
      meta: {
        title: $t('wang-dian-zhuang-xiu'),
        id: 1001001,
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: true,
        showInPC: true
      }
    }, {
      path: '/setting/shop/links',
      name: 'links',
      component: Links,
      meta: {
        title: $t('lian-jie-da-quan'),
        id: 1001002,
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: true
      }
    }, {
      path: '/setting/categoryManage',
      name: 'categoryManage',
      component: CategoryManage,
      meta: {
        title: $t('lei-mu-guan-li'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008006,
        show: true
      }
    }, {
      path: '/setting/projectManage',
      name: 'projectEdit',
      component: ProjectEdit,
      meta: {
        title: $t('jiu-zhen-pei-zhi'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008007,
        show: true
      }
    }, {
      path: '/setting/paymethod',
      name: 'paymentSetting',
      component: PaymentSetting,
      meta: {
        title: $t('shou-kuan-lei-xing-guan-li'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008004,
        show: true
      }
    }, {
      path: '/setting/logisticsCompany',
      name: 'logisticsCompany',
      component: LogisticsCompany,
      meta: {
        title: $t('kuai-di-gong-si-she-zhi'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008005,
        show: true
      }
    }, {
      path: '/setting/payment',
      name: 'paySetting',
      component: PaySetting,
      meta: {
        title: $t('di-san-fang-she-zhi'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008001,
        show: true
      }
    }, {
      path: '/setting/monitorSetting',
      name: 'monitorSetting',
      component: MonitorSetting,
      meta: {
        title: $t('gong-gong-qu-yu-jian-kong-guan-li'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008008,
        show: true
      }
    },{
      path: '/setting/commission',
      name: 'commissionProgram',
      component: CommissionProgram,
      meta: {
        title: $t('ti-cheng-fang-an'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps', 
        id: 1008010,
        show: true
      }
    }, {
      path: '/setting/commission/add',
      name: 'commissionAdd',
      component: CommissionAdd,
      meta: {
        title: $t('xin-zeng-ti-cheng-fang-an'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008010,
        show: false
      }
    }, {
      path: '/setting/commission/edit', 
      name: 'commissionEdit',
      component: CommissionEdit,
      meta: {
        title: $t('bian-ji-ti-cheng-fang-an'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008010,
        show: false
      }
    }, {
      path: '/setting/commission/detail',
      name: 'commissionDetail', 
      component: CommissionDetail,
      meta: {
        title: $t('ti-cheng-fang-an-xiang-qing'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008010,
        show: false
      }
    }, {
      path: '/setting/categoryManage/add',
      name: 'categoryAdd',
      component: CategoryEdit,
      meta: {
        title: $t('xin-zeng-lei-mu'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008001,
        show: false
      }
    }, {
      path: '/setting/categoryManage/edit',
      name: 'categoryEdit',
      component: CategoryEdit,
      meta: {
        title: $t('bian-ji-lei-mu'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008001,
        show: false
      }
    }, {
      path: '/setting/categoryManage/detail',
      name: 'categoryDetail',
      component: CategoryEdit,
      meta: {
        title: $t('lei-mu-xiang-qing'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008001,
        show: false
      }
    }, {
      path: '/setting/shop/editShopInfo',
      name: 'editShopInfo',
      component: EditShopInfo,
      meta: {
        title: $t('bian-ji-men-dian'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008001,
        show: false
      }
    }, {
      path: '/setting/shop/shopAuthGuide',
      name: 'shopAuthGuide',
      component: ShopAuthGuide,
      meta: {
        title: $t('dian-pu-ren-zheng-yin-dao'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008001,
        show: false
      }
    }, {
      path: '/setting/shop/shopAuth',
      name: 'shopAuth',
      component: ShopAuth,
      meta: {
        title: $t('dian-pu-ren-zheng'),
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        id: 1008001,
        show: false
      }
    }]
  },
  {
    path: '/setting/shop/shopDecoration/edit',
    name: 'shopPackage',
    component: ShopPackage,
    meta: {
      title: $t('wang-dian-zhuang-xiu-0'),
      icon: 'el-icon-menu',
      icon_m: 'md-apps',
      id: 1001001,
      show: false
    },
    redirect: '/setting/shop/shopDecoration/edit/index',
    children: [
      {
        path: '/setting/shop/shopDecoration/edit/index',
        name: 'shopPackageInner',
        component: ShopGroup,
        meta: {
          title: $t('wang-dian-ying-yong'),
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          id: 1001001001,
          show: false
        }
      }
    ]
  },
  {
    path: '/setting/shop/shopDecoration/edit/mycenter',
    name: 'shopPackageMyCenter',
    component: ShopMyCenter,
    meta: {
      title: $t('ge-ren-zhong-xin-ying-yong'),
      icon: 'el-icon-menu',
      icon_m: 'md-apps',
      id: 1001001003,
      show: false
    }
  },
  {
    path: '/myInfo',
    meta: {
      title: $t('wo-de-xin-xi'),
      icon: 'icon-manage-menu-3',
      icon_m: 'md-apps',
      id: 100,
      show: false
    },
    redirect: '/myInfo/index',
    component: Layer,
    children: [{
      path: '/myInfo/index',
      name: 'myInfo',
      component: MyInfo,
      meta: {
        title: $t('wo-de-xin-xi-0'),
        id: 100,
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }]
  }
]

export default shopRouter
